import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../../common/components/genericDropdown"
import CampaignsCreationConsts from "../../../campaignCreationConsts"
import { updateTargetingGroup } from "../../../campaignCreationActions"
import { isNullOrEmpty } from "../../../../utils/funcUtils"

const PlacementsDropdown = (props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [menuItems, setMenuItems] = useState(getPlacements())

  function isAllPlatformsSelected() {
    return Object.values(props.campaignTargetingGroup.platforms).every(
      (platformData) =>
        platformData.selected && Object.values(platformData.displayedName || {}).every((displayedName) => displayedName)
    )
  }

  // Function to update placements dynamically based on selected platforms
  function getPlacements() {
    if (props.allowSelectAutomatic && isAllPlatformsSelected()) {
      return [CampaignsCreationConsts.AUTOMATIC_PLACEMENTS, ...CampaignsCreationConsts.FACEBOOK_PLACEMENTS]
    }
    return [...CampaignsCreationConsts.FACEBOOK_PLACEMENTS]
  }

  const handleDefaultSelection = () => {
    if (props.allowSelectAutomatic && isAllPlatformsSelected()) {
      const defaultPlacement = menuItems.find((placement) => placement.key === "automatic")
      if (!isNullOrEmpty(defaultPlacement)) {
        handleMenuClick([menuItems.indexOf(defaultPlacement)])
      }
    } else {
      // Select all placements
      const allPlacementIndexes = menuItems
        .map((option, index) => {
          return { ...option, optionIndex: index }
        })
        .filter((option) => !option.isTitle)
        .map((option) => option.optionIndex)

      handleMenuClick(allPlacementIndexes)
    }
  }

  useEffect(() => {
    handleDefaultSelection()
  }, [menuItems])

  useEffect(() => {
    const placements = getPlacements()

    // Check if the placements menu has changed
    if (placements.length !== menuItems.length) {
      setMenuItems(placements)
    }
  }, [props.campaignTargetingGroup.platforms])

  const handleMenuOpen = (event) => {
    setMenuOpen(true)
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  const handleMenuClick = (selectedIndexes) => {
    const selectedPlacements = selectedIndexes.map((index) => menuItems[index])

    let newSourceData = Object.assign({}, props.campaignTargetingGroup, {
      selectedPlacements,
      selectedPlacementsIndexesArr: selectedIndexes,
    })

    if (props.onChange) {
      props.onChange(newSourceData)
    } else {
      props.dispatch(updateTargetingGroup(props.groupIndex, newSourceData))
    }
  }

  // Configure dropdown button header
  let isFieldDisabled = !props.siteId
  let header = "Choose placement"
  let buttonClassName = "creation-generic-dropdown-button "

  if (props.campaignTargetingGroup.selectedPlacements && props.campaignTargetingGroup.selectedPlacements.length > 0) {
    buttonClassName += "selected"

    if (props.campaignTargetingGroup.selectedPlacementsIndexesArr.length === 1) {
      header = props.campaignTargetingGroup.selectedPlacements[0].name
    } else {
      header = props.campaignTargetingGroup.selectedPlacementsIndexesArr.length + " placements selected"
    }
  }

  return (
    <div className={`text-field placements-selection ${isFieldDisabled ? "disabled " : ""}`}>
      <div className="creation-generic-dropdown-label">Placements</div>
      <GenericDropdown
        header={header}
        selectOptionCallback={handleMenuClick}
        allowSelectAll
        options={menuItems}
        open={menuOpen}
        anchorEl={menuAnchorEl}
        selectedIndexes={props.campaignTargetingGroup.selectedPlacementsIndexesArr}
        openDropdownCallback={(event) => handleMenuOpen(event)}
        allowMultiple
        allowAutoSuggest
        showEmptyOption
        emptyOptionText="None"
        closeDropdownCallback={handleMenuClose}
        allowNoSelection
        buttonClassName={buttonClassName}
        menuClassName="creation-generic-dropdown-menu"
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    siteId: ownProps.siteId ? ownProps.siteId : state.campaignCreationWizard.campaignSettings.siteId,
  }
}

export default connect(mapStateToProps)(PlacementsDropdown)
