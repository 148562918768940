import React from "react"
import { Button, CircularProgress, Dialog, Switch, TextField } from "@material-ui/core"
import { connect } from "react-redux"
import merge from "deepmerge"
import Xicon from "../../../resources/svgs/Xicon.svg"
import NameSuffix from "../../../campaignCreation/components/fields/targetingGroupsFields/nameSuffix"
import { addNetworkTargetingGroupPresetAsync, updateNetworkTargetingGroupPreseAsync } from "../../settingsPageActions"
import GenericDropdown from "../../../common/components/genericDropdown"
import CampaignsCreationSourcesInitialState from "../../../campaignCreation/campaignCreationSourcesInitialState"
import TagsDropdown from "../../../campaignCreation/components/dropdowns/targetingGroupsDropdowns/tagsDropdown"
import CampaignServiceV2 from "../../../api/campaignServiceV2"
import CountriesAndCountryGroupsDropdown from "../../../campaignCreation/components/dropdowns/targetingGroupsDropdowns/countriesAndCountryGroupsDropdown"
import CampaignsConsts from "../../../campaignsV2/campaignsConsts"
import PlacementsDropdown from "../../../campaignCreation/components/dropdowns/targetingGroupsDropdowns/placementsDropdown"
import PlatformSelection from "../../../campaignCreation/components/dropdowns/targetingGroupsDropdowns/platformSelection"
import WarningIconSmall from "../../../resources/svgs/WarningIconSmall.svg"
import CreationValidator from "../../../campaignCreation/creationValidator"
import { getPresetConversionEventsOptions } from "../../../utils/campaignUtilsV2"
import ConversionEventsDropdown from "../../../campaignCreation/components/dropdowns/targetingGroupsDropdowns/conversionEventsDropdown"
import BrowsersDropdown from "../../../campaignCreation/components/dropdowns/targetingGroupsDropdowns/browsersDropdown"
import InitialBidAndBudget from "../../../campaignCreation/components/dropdowns/targetingGroupsDropdowns/InitialBidAndBudget"
import StatusSwitch from "../../../campaignCreation/components/dropdowns/targetingGroupsDropdowns/statusSwitch"
import CampaignTypeDropdown from "../../../campaignCreation/components/dropdowns/targetingGroupsDropdowns/campaignTypeDropdown"

class TargetingGroupPresetPopup extends React.Component {
  constructor(props) {
    super(props)

    // Initial state of a targeting group. this is the default state that all presets will be initiated with,
    // and it also describes all the fields that are legal in a preset
    this.targetingGroupPresetInitialState = {
      isTargetingGroupPreset: true,
      groupName: "",
      campaignTypes: [],
      selectedCountries: [],
      selectedCountryGroups: [],
      allowMultipleCountries: false,
      platforms: {},
      selectedTags: [],
      nameSuffix: "",
      initialBid: null,
      initialBudget: null,
      selectedPlacements: null,
      selectedPlacementsIndexesArr: [],
      selectedConversionEvents: [],
      browsers: [],
      id: null,
      isActive: false,
    }

    this.state = {
      isLoading: false,
      isLoadingTags: false,
      providersMenuOpen: false,
      providersMenuAnchorEl: null,
      providersMenuSelectedIndexesArr: [],
      networkTags: [],
      validationErrorsVisible: false,

      selectedProvider: null,
      targetingGroupPreset: this.targetingGroupPresetInitialState,
    }

    // Prepare an array of providers that will be easier to iterate over
    this.providerOptions = []

    Object.keys(CampaignsCreationSourcesInitialState.CAMPAIGN_CREATION_SOURCES).forEach((providerKey) => {
      this.providerOptions.push({
        ...CampaignsCreationSourcesInitialState.CAMPAIGN_CREATION_SOURCES[providerKey],
        name: providerKey,
      })
    })
  }

  onTargetingGroupPropertyChange = (changedPropertyObj) => {
    this.setState({
      targetingGroupPreset: {
        ...this.state.targetingGroupPreset,
        ...changedPropertyObj,
      },
    })
  }

  getValidationErrors = () => {
    return CreationValidator.validateTargetingGroupPreset(this.state.targetingGroupPreset, this.state.selectedProvider)
  }

  createTargetingGroupPreset = () => {
    this.setState(
      {
        validationErrorsVisible: true,
      },
      () => {
        let validationErrors = this.getValidationErrors()

        if (validationErrors.size === 0) {
          this.setState({
            isLoading: true,
          })

          // Removing unselected conversion events to preserve space, while saving the names of the ones that we selected
          // for reloading them later (in the edit mode or in the actual campaign creation
          let targetingGroupPreset = this.state.targetingGroupPreset
          // Default save button action: add new preset
          let action = addNetworkTargetingGroupPresetAsync(this.props.networkId, this.state.selectedProvider.id, {
            ...targetingGroupPreset,
          })

          if (this.props.isEdit) {
            // Action is changed in case we're editing an existing group
            action = updateNetworkTargetingGroupPreseAsync(
              this.props.existingTargetingGroupPresetData.id,
              this.props.networkId,
              this.state.selectedProvider.id,
              {
                ...targetingGroupPreset,
              }
            )
          }

          this.props
            .dispatch(action)
            .then(() => {
              this.props.closeDialog()
            })
            .finally(() => {
              this.setState({
                isLoading: false,
              })
            })
        }
      }
    )
  }

  fetchTags = () => {
    if (this.state.networkTags.length === 0 && !this.state.isLoadingTags) {
      this.setState({
        isLoadingTags: true,
      })
      CampaignServiceV2.getNetworkTags(this.props.networkCode).then((result) => {
        this.setState({
          isLoadingTags: false,
          networkTags: result.data.network_tags.map((tag) => {
            return { name: tag }
          }),
        })
      })
    }
  }

  resetForm = () => {
    if (this.props.existingTargetingGroupPresetData) {
      let selectedProvider = this.providerOptions.filter(
        (provider) => provider.id === this.props.existingTargetingGroupPresetData.provider_id
      )[0]
      let targetingGroupPresetToEdit = JSON.parse(this.props.existingTargetingGroupPresetData.targeting_json)

      let presetKeys = Object.keys(targetingGroupPresetToEdit)
      let initialStateKeys = Object.keys(this.targetingGroupPresetInitialState)

      // Removing keys from the targeting group that can't exist in the preset, this will also remove the selected
      // provider account if we're saving a preset from the creation wizard
      presetKeys.forEach((presetKey) => {
        if (!initialStateKeys.includes(presetKey)) {
          delete targetingGroupPresetToEdit[presetKey]
        }
      })

      targetingGroupPresetToEdit = {
        ...this.targetingGroupPresetInitialState,
        ...targetingGroupPresetToEdit,
      }

      this.setState({
        selectedProvider,
        targetingGroupPreset: targetingGroupPresetToEdit,
        validationErrorsVisible: false,
      })
    } else {
      this.setState({
        selectedProvider: null,
        targetingGroupPreset: this.targetingGroupPresetInitialState,
        validationErrorsVisible: false,
      })
    }

    this.fetchTags()
  }

  providersMenuClick = (selectedIndexesArr) => {
    let selectedProvider = merge({}, this.providerOptions[selectedIndexesArr[0]])
    let selectedPlatforms = selectedProvider.platforms

    this.setState({
      providersMenuSelectedIndexesArr: selectedIndexesArr,
      selectedProvider,
      providersMenuOpen: false,

      targetingGroupPreset: {
        ...this.state.targetingGroupPreset,
        campaignTypes: selectedProvider.campaignTypes,
        platforms: selectedPlatforms,
        selectedConversionEvents: [],
        browsers: selectedProvider.browsers,
        id: selectedProvider.id,
      },
    })
  }

  providersMenuOpen = (event) => {
    this.setState({
      providersMenuOpen: true,
      providersMenuAnchorEl: event.currentTarget,
    })
  }

  providersMenuClose = () => {
    this.setState({
      providersMenuOpen: false,
    })
  }

  renderProviderDropdown = (providerValidationErrors) => {
    let header = "Choose source"
    let buttonClassName = "creation-generic-dropdown-button "

    if (this.state.selectedProvider) {
      buttonClassName += "selected"
      header = this.state.selectedProvider.name
    }

    return (
      <div className={"text-field page-selection " + (providerValidationErrors ? "error " : "")}>
        <div className="creation-generic-dropdown-label">Source</div>
        <GenericDropdown
          header={header}
          options={this.providerOptions}
          open={this.state.providersMenuOpen}
          selectOptionCallback={(selectedIndexesArr) => {
            this.providersMenuClick(selectedIndexesArr)
          }}
          anchorEl={this.state.providersMenuAnchorEl}
          selectedIndexes={this.state.providersMenuSelectedIndexesArr}
          allowAutoSuggest={false}
          allowNoSelection={false}
          openDropdownCallback={(event) => this.providersMenuOpen(event)}
          closeDropdownCallback={this.providersMenuClose}
          buttonClassName={buttonClassName}
          menuClassName="creation-generic-dropdown-menu"
        />
        {providerValidationErrors}
      </div>
    )
  }

  renderGroupNameTextField = (groupNameValidationErrors) => {
    return (
      <div className={"text-field " + (groupNameValidationErrors ? "error " : "")}>
        <TextField
          label="Group name"
          InputProps={{
            className: "text-field-line",
          }}
          InputLabelProps={{
            classes: { shrink: "text-field-color" },
          }}
          placeholder="Name suffix"
          value={this.state.targetingGroupPreset.groupName}
          onChange={(event) => this.onTargetingGroupPropertyChange({ groupName: event.target.value })}
          margin="normal"
        />
        {groupNameValidationErrors}
      </div>
    )
  }

  generateValidationError = (validationErrors, field) => {
    let error = validationErrors.get(field)

    if (!this.state.validationErrorsVisible) {
      return null
    }

    if (error) {
      return (
        <div className="validation-error">
          <WarningIconSmall />
          <span className="text">{validationErrors.get(field)}</span>
        </div>
      )
    }

    return null
  }

  renderFields = () => {
    let fieldLoading = (
      <div className="loading-wrapper">
        <CircularProgress size={30} />
      </div>
    )

    let firstNetworkSiteId = this.props.networkToSites
      .filter((network) => network.key == this.props.networkId)[0]
      .data.map((site) => site.id)[0]

    let providerValidationErrors = null
    let groupNameValidationErrors = null
    let platformsValidationErrors = null
    let conversionEventsValidationErrors = null
    let countriesValidationErrors = null
    let nameSuffixValidationErrors = null
    let initialBidValidationErrors = null
    let initialBudgetValidationErrors = null
    let groupPresetErrors = null

    let validationErrors = this.getValidationErrors()
    if (validationErrors) {
      providerValidationErrors = this.generateValidationError(validationErrors, "provider")
      groupNameValidationErrors = this.generateValidationError(validationErrors, "groupName")
      platformsValidationErrors = this.generateValidationError(validationErrors, "sources")
      conversionEventsValidationErrors = this.generateValidationError(validationErrors, "conversionEvents")
      countriesValidationErrors = this.generateValidationError(validationErrors, "countries")
      nameSuffixValidationErrors = this.generateValidationError(validationErrors, "nameSuffix")
      initialBidValidationErrors = this.generateValidationError(validationErrors, "initialBid")
      initialBudgetValidationErrors = this.generateValidationError(validationErrors, "initialBudget")
      groupPresetErrors = this.generateValidationError(validationErrors, "groupPreset")
    }

    return (
      <>
        {this.renderProviderDropdown(providerValidationErrors)}
        {this.renderGroupNameTextField(groupNameValidationErrors)}

        {this.state.selectedProvider ? (
          <PlatformSelection
            campaignTargetingGroup={this.state.targetingGroupPreset}
            platformsValidationErrors={platformsValidationErrors}
            selectedSource={this.state.selectedProvider}
            onChange={(value) => this.onTargetingGroupPropertyChange(value)}
          />
        ) : null}

        <CountriesAndCountryGroupsDropdown
          campaignTargetingGroup={this.state.targetingGroupPreset}
          countriesValidationErrors={countriesValidationErrors}
          networkId={this.props.networkId}
          onChange={(value) => this.onTargetingGroupPropertyChange(value)}
        />

        {this.state.selectedProvider ? (
          <ConversionEventsDropdown
            conversionEvents={getPresetConversionEventsOptions(this.state.selectedProvider.id)}
            campaignTargetingGroup={this.state.targetingGroupPreset}
            conversionEventsValidationErrors={conversionEventsValidationErrors}
            onChange={(value) => this.onTargetingGroupPropertyChange(value)}
          />
        ) : null}

        {this.state.selectedProvider && this.state.selectedProvider.id === CampaignsConsts.FACEBOOK_PROVIDER_ID ? (
          <>
            <PlacementsDropdown
              campaignTargetingGroup={this.state.targetingGroupPreset}
              siteId={firstNetworkSiteId}
              onChange={(value) => this.onTargetingGroupPropertyChange(value)}
              allowSelectAutomatic={false}
            />
          </>
        ) : null}

        {this.state.selectedProvider && this.state.selectedProvider.id === CampaignsConsts.TABOOLA_PROVIDER_ID ? (
          <BrowsersDropdown
            campaignTargetingGroup={this.state.targetingGroupPreset}
            onChange={(value) => this.onTargetingGroupPropertyChange(value)}
          />
        ) : null}

        {this.state.isLoadingTags ? (
          fieldLoading
        ) : (
          <TagsDropdown
            campaignTargetingGroup={this.state.targetingGroupPreset}
            networkTags={this.state.networkTags}
            onChange={(value) => this.onTargetingGroupPropertyChange(value)}
          />
        )}
        {this.state.selectedProvider ? (
          <>
            <InitialBidAndBudget
              bidType={this.state.targetingGroupPreset.campaignTypes.find((type) => type.selected)?.id}
              campaignTargetingGroup={this.state.targetingGroupPreset}
              initialBidValidationErrors={initialBidValidationErrors}
              initialBudgetValidationErrors={initialBudgetValidationErrors}
              onChange={(value) => this.onTargetingGroupPropertyChange(value)}
              conversionEvents={getPresetConversionEventsOptions(this.state.selectedProvider.id)}
              isPreset={true}
            />
            <CampaignTypeDropdown
              campaignTargetingGroup={this.state.targetingGroupPreset}
              onChange={(event) => this.onTargetingGroupPropertyChange(event)}
              isPreset={true}
            />
          </>
        ) : null}
        <StatusSwitch
          campaignTargetingGroup={this.state.targetingGroupPreset}
          onChange={(value) => this.onTargetingGroupPropertyChange(value)}
        />

        <NameSuffix
          campaignTargetingGroup={this.state.targetingGroupPreset}
          nameSuffixValidationErrors={nameSuffixValidationErrors}
          onChange={(value) => this.onTargetingGroupPropertyChange(value)}
        />

        {groupPresetErrors}
      </>
    )
  }

  render() {
    let dialogTitle = "Create Targeting Group"

    if (this.props.isEdit) {
      dialogTitle = "Edit Targeting Group"
    }

    let createButton = (
      <Button
        className="create-targeting-group-preset-button round-button blue"
        onClick={this.createTargetingGroupPreset}
      >
        {this.props.isEdit ? "Save" : "Create"}
      </Button>
    )

    if (this.state.isLoading) {
      createButton = (
        <div className="create-targeting-group-preset-button d-flex align-items-center justify-content-center">
          <CircularProgress className="loader" size={20} />
        </div>
      )
    }

    return (
      <Dialog
        open={this.props.dialogOpen}
        BackdropProps={{
          classes: { root: "campaign-popup-backdrop" },
        }}
        onEntering={this.resetForm}
        classes={{ paper: "targeting-group-preset-popup", root: "targeting-group-preset-popup-container" }}
        onClose={this.props.closeDialog}
      >
        <div>
          <Xicon className="exit-button clickable" width="14" height="14" onClick={this.props.closeDialog} />
          <div className="title">{dialogTitle}</div>

          {this.renderFields()}

          <div className="actions">
            {createButton}
            <span className="cancel-button clickable" onClick={this.props.closeDialog}>
              Cancel
            </span>
          </div>
        </div>
      </Dialog>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    countries: state.app.countries,
    networkToSites: state.app.networkToSites,
    allConversionEvents: state.app.conversionEvents,
  }
}

export default connect(mapStateToProps)(TargetingGroupPresetPopup)
