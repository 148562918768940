import articlesTypeConsts from "../articles/components/articlesType"
import CampaignsConsts from "../campaignsV2/campaignsConsts"
import CampaignsTableConsts from "../campaignsV2/campaignsTableConsts"

const common_revenue = {
  visits: CampaignsTableConsts.data_columns.visits,
  visitValue: CampaignsTableConsts.data_columns.visitValue,
  pagesPerVisit: CampaignsTableConsts.data_columns.pagesPerVisit,
  bounceRate: CampaignsTableConsts.data_columns.bounceRate,
  revenue: CampaignsTableConsts.data_columns.revenue,
}

const common_spend = {
  clicks: CampaignsTableConsts.data_columns.clicks,
  costPerClick: CampaignsTableConsts.data_columns.costPerClick,
  roi: CampaignsTableConsts.data_columns.roi,
  profit: CampaignsTableConsts.data_columns.profit,
  dailySpent: CampaignsTableConsts.data_columns.dailySpent,
  visitDuration: CampaignsTableConsts.data_columns.visitDuration,
  ctr: CampaignsTableConsts.data_columns.ctr,
}

const common_revenue_rsoc = {
  visits: CampaignsTableConsts.data_columns.visits,
}

const common_spend_rsoc = {
  ...Object.fromEntries(Object.entries(common_spend).filter(([key]) => key !== "ctr" && key !== "visitDuration")),
}

// Define Provider Campaign Configuration
export const PROVIDER_CAMPAIGN_CONFIG = {
  [CampaignsConsts.TABOOLA_PROVIDER_ID]: {
    [articlesTypeConsts.ads_for_search.name]: {
      country: 1,
      sub_source: 2,
      browser: 3,
      deviceevice: 4,
    },
    [articlesTypeConsts.content.name]: {
      country: 5,
      sub_source: 6,
      browser: 7,
      device: 8,
    },
  },
  [CampaignsConsts.FACEBOOK_PROVIDER_ID]: {
    [articlesTypeConsts.ads_for_search.name]: {
      country: 9,
      sub_source: 10,
      browser: 11,
      device: 12,
    },
    [articlesTypeConsts.content.name]: {
      country: 13,
      sub_source: 14,
      browser: 15,
      device: 16,
    },
  },
  [CampaignsConsts.GOOGLE_PROVIDER_ID]: {
    [articlesTypeConsts.ads_for_search.name]: {
      country: 17,
      sub_source: 18,
      browser: 19,
      device: 20,
    },
    [articlesTypeConsts.content.name]: {
      country: 21,
      sub_source: 22,
      browser: 23,
      device: 24,
    },
  },
  [CampaignsConsts.PINTEREST_PROVIDER_ID]: {
    [articlesTypeConsts.ads_for_search.name]: {
      country: 25,
      sub_source: 26,
      browser: 27,
      device: 28,
    },
    [articlesTypeConsts.content.name]: {
      country: 29,
      sub_source: 30,
      browser: 31,
      device: 32,
    },
  },
}

// Define Column Mapping
export const COLUMN_MAPPING = {
  1: { country: CampaignsTableConsts.country, ...common_revenue_rsoc },
  2: {
    subSourceStatus: CampaignsTableConsts.subSourceStatus,
    subSourceBid: CampaignsTableConsts.subSourceBid,
    subSource: CampaignsTableConsts.subSource,
    ...common_revenue_rsoc,
  },
  3: { browser: CampaignsTableConsts.browser, ...common_revenue_rsoc },
  4: { device: CampaignsTableConsts.device, ...common_revenue_rsoc },
  5: { country: CampaignsTableConsts.country, ...common_revenue },
  6: {
    subSourceStatus: CampaignsTableConsts.subSourceStatus,
    subSourceBid: CampaignsTableConsts.subSourceBid,
    subSource: CampaignsTableConsts.subSource,
    ...common_revenue,
    ...common_spend,
  },
  7: { browser: CampaignsTableConsts.browser, ...common_revenue },
  8: { device: CampaignsTableConsts.device, ...common_revenue, ...common_spend },
  9: {
    country: CampaignsTableConsts.country,
    visitValue: CampaignsTableConsts.data_columns.visitValue,
    revenue: CampaignsTableConsts.data_columns.revenue,
    ...common_revenue_rsoc,
    ...common_spend_rsoc,
  },
  10: { subSource: CampaignsTableConsts.subSource, ...common_revenue_rsoc },
  11: { browser: CampaignsTableConsts.browser, ...common_revenue_rsoc },
  12: { device: CampaignsTableConsts.device, ...common_revenue_rsoc },
  13: { country: CampaignsTableConsts.country, ...common_revenue, ...common_spend },
  14: { subSource: CampaignsTableConsts.subSource, ...common_revenue, ...common_spend },
  15: { browser: CampaignsTableConsts.browser, ...common_revenue },
  16: { device: CampaignsTableConsts.device, ...common_revenue, ...common_spend },
  17: {
    country: CampaignsTableConsts.country,
    visitValue: CampaignsTableConsts.data_columns.visitValue,
    revenue: CampaignsTableConsts.data_columns.revenue,
    ...common_revenue_rsoc,
  },
  18: { subSource: CampaignsTableConsts.subSource, ...common_revenue_rsoc },
  19: { browser: CampaignsTableConsts.browser, ...common_revenue_rsoc },
  20: { device: CampaignsTableConsts.device, ...common_revenue_rsoc },
  21: { country: CampaignsTableConsts.country, ...common_revenue },
  22: { subSource: CampaignsTableConsts.subSource, ...common_revenue },
  23: { browser: CampaignsTableConsts.browser, ...common_revenue },
  24: { device: CampaignsTableConsts.device, ...common_revenue },
  25: {
    country: CampaignsTableConsts.country,
    visitValue: CampaignsTableConsts.data_columns.visitValue,
    revenue: CampaignsTableConsts.data_columns.revenue,
    ...common_revenue_rsoc,
  },
  26: { subSource: CampaignsTableConsts.subSource, ...common_revenue_rsoc },
  27: { browser: CampaignsTableConsts.browser, ...common_revenue_rsoc },
  28: { device: CampaignsTableConsts.device, ...common_revenue_rsoc },
  29: { country: CampaignsTableConsts.country, ...common_revenue },
  30: { subSource: CampaignsTableConsts.subSource, ...common_revenue },
  31: { browser: CampaignsTableConsts.browser, ...common_revenue },
  32: { device: CampaignsTableConsts.device, ...common_revenue },
}

// Define Function to Retrieve Columns for a Tab
export function getColumnsForTab(providerId, postStyle, tabName) {
  const articleType = articlesTypeConsts.ads_for_search.name === postStyle ? postStyle : articlesTypeConsts.content.name

  const configId = PROVIDER_CAMPAIGN_CONFIG[providerId]?.[articleType]?.[tabName]

  if (!configId) {
    return {}
  }

  const columnOrder = [
    "country",
    "subSourceStatus",
    "subSource",
    "subSourceBid",
    "browser",
    "device",
    // revenue and spend columns
    "clicks",
    "visits",
    "costPerClick",
    "visitValue",
    "roi",
    "profit",
    "dailySpent",
    "revenue",
    "pagesPerVisit",
    "bounceRate",
    "visitDuration",
    "ctr",
  ]

  // Convert the object to an array of key-value pairs
  const columnsArray = Object.entries(COLUMN_MAPPING[configId] || {})

  // Sort the array based on the desired order
  const sortedColumnsArray = columnsArray.sort(([keyA], [keyB]) => {
    const indexA = columnOrder.indexOf(keyA)
    const indexB = columnOrder.indexOf(keyB)

    // If a column is not in the order list, it will be placed at the end
    return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB)
  })

  // Convert the sorted array back to an object
  const sortedColumns = Object.fromEntries(sortedColumnsArray)

  return sortedColumns
}
