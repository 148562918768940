class AdminPageActionTypes {}
AdminPageActionTypes.ADMIN_RESOURCES_LOADED = "ADMIN_RESOURCES_LOADED"
AdminPageActionTypes.ADMIN_USERS_LOADING = "ADMIN_USERS_LOADING"
AdminPageActionTypes.ADMIN_FETCH_USERS_RESPONSE = "ADMIN_FETCH_USERS_RESPONSE"
AdminPageActionTypes.UPDATE_USERS_PAGE = "UPDATE_USERS_PAGE"
AdminPageActionTypes.RESET_USERS_PAGINATION = "RESET_USERS_PAGINATION"
AdminPageActionTypes.USER_POPUP_OPEN = "USER_POPUP_OPEN"
AdminPageActionTypes.USER_POPUP_CLOSE = "USER_POPUP_CLOSE"
AdminPageActionTypes.START_MAIN_LOADING = "START_MAIN_LOADING"
AdminPageActionTypes.STOP_MAIN_LOADING = "STOP_MAIN_LOADING"
AdminPageActionTypes.ALTER_USER_RESPONSE = "ALTER_USER_RESPONSE"
AdminPageActionTypes.ALTER_USER_ERROR = "ALTER_USER_ERROR"
AdminPageActionTypes.UPDATE_USER_DATA = "UPDATE_USER_DATA"
AdminPageActionTypes.UPDATE_USER_ROLES = "UPDATE_USER_ROLES"
AdminPageActionTypes.ADD_USER_ROLE = "ADD_USER_ROLE"
AdminPageActionTypes.ADD_USER_SITES = "ADD_USER_SITES"
AdminPageActionTypes.UPDATE_USER_SITES = "UPDATE_USER_SITES"
AdminPageActionTypes.REMOVE_USER_SITE = "REMOVE_USER_SITE"
AdminPageActionTypes.REMOVE_USER_ROLE = "REMOVE_USER_ROLE"
AdminPageActionTypes.RESET_USER_SITES = "RESET_USER_SITES"
AdminPageActionTypes.ADMIN_NOTIFICATION_POPUP_OPEN = "ADMIN_NOTIFICATION_POPUP_OPEN"
AdminPageActionTypes.ADMIN_NOTIFICATION_POPUP_CLOSE = "ADMIN_NOTIFICATION_POPUP_CLOSE"
AdminPageActionTypes.ADMIN_NOTIFICATIONS_LOADING = "ADMIN_NOTIFICATIONS_LOADING"
AdminPageActionTypes.FETCH_ADMIN_NOTIFICATIONS_RESPONSE = "FETCH_ADMIN_NOTIFICATIONS_RESPONSE"
AdminPageActionTypes.CREATE_ADMIN_NOTIFICATION_RESPONSE = "CREATE_ADMIN_NOTIFICATION_RESPONSE"
AdminPageActionTypes.UPDATE_ADMIN_NOTIFICATION_RESPONSE = "UPDATE_ADMIN_NOTIFICATION_RESPONSE"
AdminPageActionTypes.FILTER_USERS = "FILTER_USERS"
AdminPageActionTypes.ROLE_POPUP_OPEN = "ROLE_POPUP_OPEN"
AdminPageActionTypes.ROLE_POPUP_CLOSE = "ROLE_POPUP_CLOSE"
AdminPageActionTypes.ROLE_POPUP_LOADING = "ROLE_POPUP_LOADING"
AdminPageActionTypes.GET_ROLE_MODULES_RESPONSE = "GET_ROLE_MODULES_RESPONSE"
AdminPageActionTypes.UPDATE_ROLE_MODULES = "UPDATE_ROLE_MODULES"
AdminPageActionTypes.ADD_ROLE_RESPONSE = "ADD_ROLE_RESPONSE"
AdminPageActionTypes.UPDATE_ROLE_RESPONSE = "UPDATE_ROLE_RESPONSE"
AdminPageActionTypes.MODULE_POPUP_OPEN = "MODULE_POPUP_OPEN"
AdminPageActionTypes.MODULE_POPUP_CLOSE = "MODULE_POPUP_CLOSE"
AdminPageActionTypes.MODULE_POPUP_LOADING = "MODULE_POPUP_LOADING"
AdminPageActionTypes.GET_ROLES_USING_MODULE_RESPONSE = "GET_ROLES_USING_MODULE_RESPONSE"
AdminPageActionTypes.ADD_MODULE_RESPONSE = "ADD_MODULE_RESPONSE"
AdminPageActionTypes.UPDATE_MODULE_RESPONSE = "UPDATE_MODULE_RESPONSE"
AdminPageActionTypes.ALTER_ROLE_ERROR = "ALTER_ROLE_ERROR"
AdminPageActionTypes.ALTER_MODULE_ERROR = "ALTER_MODULE_ERROR"
AdminPageActionTypes.SITE_POPUP_OPEN = "SITE_POPUP_OPEN"
AdminPageActionTypes.SITE_POPUP_CLOSE = "SITE_POPUP_CLOSE"
AdminPageActionTypes.SITE_POPUP_LOADING = "SITE_POPUP_LOADING"
AdminPageActionTypes.ADD_SITE_RESPONSE = "ADD_SITE_RESPONSE"
AdminPageActionTypes.UPDATE_SITE_RESPONSE = "UPDATE_SITE_RESPONSE"
AdminPageActionTypes.ALTER_SITE_ERROR = "ALTER_SITE_ERROR"
AdminPageActionTypes.NETWORK_POPUP_OPEN = "NETWORK_POPUP_OPEN"
AdminPageActionTypes.NETWORK_POPUP_CLOSE = "NETWORK_POPUP_CLOSE"
AdminPageActionTypes.ADD_NETWORK_RESPONSE = "ADD_NETWORK_RESPONSE"
AdminPageActionTypes.UPDATE_NETWORK_RESPONSE = "UPDATE_NETWORK_RESPONSE"
AdminPageActionTypes.ALTER_NETWORK_ERROR = "ALTER_NETWORK_ERROR"
AdminPageActionTypes.NETWORK_POPUP_LOADING = "NETWORK_POPUP_LOADING"
AdminPageActionTypes.GET_SITE_SUGGESTED_USERS_RESPONSE = "GET_SITE_SUGGESTED_USERS_RESPONSE"
AdminPageActionTypes.SET_USER_SELECTED = "SET_USER_SELECTED"
AdminPageActionTypes.SET_USERS_SELECTED = "SET_USERS_SELECTED"
AdminPageActionTypes.RESET_SUGGESTED_USERS = "RESET_SUGGESTED_USERS"
AdminPageActionTypes.FILTER_ROLES = "FILTER_ROLES"
AdminPageActionTypes.FILTER_MODULES = "FILTER_MODULES"
AdminPageActionTypes.FILTER_SITES = "FILTER_SITES"
AdminPageActionTypes.FILTER_NETWORKS = "FILTER_NETWORKS"
AdminPageActionTypes.UPDATE_BILLING_DATA = "UPDATE_BILLING_DATA"
AdminPageActionTypes.BILLING_POPUP_OPEN = "BILLING_POPUP_OPEN"
AdminPageActionTypes.BILLING_POPUP_CLOSE = "BILLING_POPUP_CLOSE"
AdminPageActionTypes.UPDATE_SEARCH_DATA = "UPDATE_SEARCH_DATA"
AdminPageActionTypes.UPDATE_SITE_TOTAL_CHANNELS = "UPDATE_SITE_TOTAL_CHANNELS"

export default AdminPageActionTypes
