import Moment from "moment"
import Consts from "../../../app/consts"
import { deserializeDateFilterPreset } from "../../../utils/funcUtils"

export class DateFilter {
  static dateEqual = (x, y) => {
    const values = deserializeDateFilterPreset(y)
    const dateX = Moment(x, Consts.SERVER_DATETIME_FORMAT).startOf("day").utc()
    const dateY = values.firstValue.startOf("day").utc()
    if (!dateX.isValid() || !dateY.isValid()) {
      return false
    }
    return dateX.isSame(dateY, "day")
  }

  static dateBefore = (x, y) => {
    let values = deserializeDateFilterPreset(y)
    return Moment(x, Consts.SERVER_DATETIME_FORMAT).isBefore(Moment(values.firstValue).add(1, "day"), "day")
  }

  static dateAfter = (x, y) => {
    let values = deserializeDateFilterPreset(y)
    return Moment(x, Consts.SERVER_DATETIME_FORMAT).isAfter(Moment(values.firstValue).subtract(1, "day"), "day")
  }

  static dateBetween = (x, min, max) => {
    let values = deserializeDateFilterPreset(min, max)
    return Moment(x, Consts.SERVER_DATETIME_FORMAT).isBetween(
      Moment(values.firstValue).subtract(1, "day"),
      Moment(values.secondValue).add(1, "day"),
      "day"
    )
  }

  static dateNotBetween = (x, min, max) => {
    return !DateFilter.dateBetween(x, min, max)
  }

  static dateNotExist = (x) => {
    return x === null
  }
}
