import React from "react"
import { Button, CircularProgress, Dialog } from "@material-ui/core"
import Xicon from "../resources/svgs/Xicon.svg"
import { connect } from "react-redux"
import { isNullOrEmpty, isNullOrUndefined } from "../utils/funcUtils"
import AutomationCreationDetails from "./components/automationCreationDetails"
import {
  closeAutomationCreationPopup,
  createAutomationResponse,
  editAutomationResponse,
} from "./automationCreationActions"
import AutomationService from "../api/automationService"
import AutomationsConsts from "../automations/automationsConsts"
import { serializeConditions } from "../utils/automationUtils"
import Consts from "../app/consts"
import NetworkTypes from "../common/consts/networkTypes"
import AutomationCreationConsts from "./automationCreationConsts"
import CampaignsConsts from "../campaignsV2/campaignsConsts"
import { range } from "../utils/numberUtils"
import AutomationsConditionsTypes from "../automations/automationsConditionsTypes"

class AutomationCreationPopup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      validationErrorsVisible: false,
      automationName: props.automationCreation.automationName,
      selectedSource: props.automationCreation.selectedSource,
      selectedActionType: props.automationCreation.selectedActionType,
      selectedActionOperation: props.automationCreation.selectedActionOperation,
      selectedValueType: props.automationCreation.selectedValueType,
      actionValue: props.automationCreation.actionValue,
      actionLimit: props.automationCreation.actionLimit,
      conditions: props.automationCreation.conditions,
      selectedTimeFrame: props.automationCreation.selectedTimeFrame,
      selectedFrequency: props.automationCreation.selectedFrequency,
      creationIsLoading: false,
      creationError: null,
      selectedNetwork: props.automationCreation.selectedNetwork || this.props.currentNetwork,
      ruleId: props.automationCreation.ruleId,
      automationStatus: props.automationCreation.automationStatus,
      selectedScheduleType: props.automationCreation.selectedScheduleType,
      selectedSpecificHours: props.automationCreation.selectedSpecificHours,
    }
  }

  closePopup = () => {
    this.setState({
      validationErrorsVisible: false,
      creationIsLoading: false,
      creationError: null,
      selectedNetwork: null,
    })

    this.props.dispatch(closeAutomationCreationPopup())
  }

  componentDidUpdate(prevProps) {
    if (prevProps.automationCreation !== this.props.automationCreation) {
      this.setState({
        automationName: this.props.automationCreation.automationName,
        selectedSource: this.props.automationCreation.selectedSource,
        selectedActionType: this.props.automationCreation.selectedActionType,
        selectedValueType: this.props.automationCreation.selectedValueType,
        selectedActionOperation: this.props.automationCreation.selectedActionOperation,
        actionValue: this.props.automationCreation.actionValue,
        actionLimit: this.props.automationCreation.actionLimit,
        conditions: this.props.automationCreation.conditions,
        selectedTimeFrame: this.props.automationCreation.selectedTimeFrame,
        selectedFrequency: this.props.automationCreation.selectedFrequency,
        selectedNetwork: this.props.automationCreation.selectedNetwork || this.props.currentNetwork,
        ruleId: this.props.automationCreation.ruleId,
        automationStatus: this.props.automationCreation.automationStatus,
        selectedScheduleType: this.props.automationCreation.selectedScheduleType,
        selectedSpecificHours: this.props.automationCreation.selectedSpecificHours,
      })
    }
  }

  handleChange = (name, event) => {
    let value = event.target.value
    this.setState({
      [name]: value,
    })
  }

  handleActionValueChange = (actionValue) => {
    // Check if the actionValue is positive or negative and change the operation type accordingly
    const isPositiveValue = actionValue >= 0
    const selectedActionOperation = isPositiveValue
      ? AutomationCreationConsts.ACTION_OPERATIONS.find((op) => op.operation === "INCREASE")
      : AutomationCreationConsts.ACTION_OPERATIONS.find((op) => op.operation === "DECREASE")

    this.setState({
      selectedActionOperation,
      actionValue,
    })
  }

  handleSelect = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  handleSelectMultiple = (valuesObj) => {
    this.setState(valuesObj)
  }

  handleFilters = (filters) => {
    this.setState({
      conditions: filters,
    })
  }

  handleOperationTypeChange = (selectedActionOperation) => {
    // Check if the selected operation is "increase" or "decrease" and change the value accordingly
    const isPositiveValue = selectedActionOperation.operation === AutomationsConsts.AUTOMATION_OPERATION_INCREASE
    const actionValue = isPositiveValue ? Math.abs(this.state.actionValue) : -Math.abs(this.state.actionValue)
    // reset the action limit
    const actionLimit = isNullOrEmpty(this.state.actionLimit)
      ? AutomationCreationConsts.ACTION_LIMIT_BY_OPERATIONS[selectedActionOperation.operation][
          this.state.selectedActionType.actionType
        ]
      : this.state.actionLimit

    this.setState({
      actionValue,
      actionLimit,
    })
  }

  isEmpty = (field) => {
    return isNullOrEmpty(field) || field.trim() === ""
  }

  validateAutomationCreation = (automationDetails) => {
    let errors = new Map()
    const bidActions = [Consts.ACTION_TYPE_BID, Consts.ACTION_TYPE_SUB_SOURCE_BID]
    const actionType = automationDetails.selectedActionType?.actionType
    const valueType = automationDetails.selectedValueType?.valueType
    const actionValue = automationDetails.actionValue
    const automationName = automationDetails.automationName
    const actionLimit = automationDetails.actionLimit
    const conditions = automationDetails.conditions
    const providerId = automationDetails.selectedSource?.providerId
    let validNameChars = new Set(range(32, 126)) // Valid chars are ascii range 32-126
    validNameChars.add(177) // Add support for ±

    if (isNullOrUndefined(automationDetails)) {
      return errors
    }

    // Selected Network
    if (
      isNullOrUndefined(automationDetails.selectedNetwork) ||
      NetworkTypes.SPECIAL_NETWORK_CODES.includes(automationDetails.selectedNetwork.code)
    ) {
      errors.set("selectedNetwork", "Network is required")
    }

    // Automation Name
    if (this.isEmpty(automationName)) {
      errors.set("automationName", "Rule name is required")
    } else {
      let invalidChars = automationName.split("").filter((char) => {
        return !validNameChars.has(char.charCodeAt(0))
      })

      if (invalidChars.length > 0) {
        errors.set("automationName", "The automation name includes unsupported characters: " + invalidChars.join(""))
      }

      if (automationName.length > 150) {
        errors.set("automationName", "The automation name is too long")
      }
    }

    // Action Type
    if (isNullOrUndefined(actionType)) {
      errors.set("selectedActionType", "Action type is required")
    }

    // Action Status Value
    if (actionType === Consts.ACTION_TYPE_STATUS) {
      if (isNullOrUndefined(actionValue)) {
        errors.set("statusActionValue", "Status action is required")
      }
    }

    if ([...bidActions, Consts.ACTION_TYPE_BUDGET].includes(actionType)) {
      // Action Value / Limit Invalid
      if (isNullOrEmpty(actionValue) || parseFloat(actionValue) === 0 || isNaN(actionValue)) {
        errors.set("actionValue", "Action value is invalid")
      }

      if (isNullOrEmpty(actionLimit) || parseFloat(actionLimit) <= 0 || isNaN(actionLimit)) {
        errors.set("actionLimit", "Action limit is invalid")
      }

      // Action Value Max
      if (valueType === AutomationsConsts.VALUE_TYPE_PERCENTAGE) {
        if (actionValue >= AutomationCreationConsts.ACTION_MAX_PERCENTAGE) {
          errors.set("actionValue", "Action value is too high")
        }
      } else if (valueType === AutomationsConsts.VALUE_TYPE_FIXED) {
        if (
          (actionType === Consts.ACTION_TYPE_BUDGET &&
            actionValue >= AutomationCreationConsts.ACTION_MAX_DAILY_BUDGET) ||
          (bidActions.includes(actionType) && actionValue >= AutomationCreationConsts.ACTION_MAX_BID)
        ) {
          errors.set("actionValue", "Action value is too high")
        }
      }

      // Action Limit Max
      if (actionType === Consts.ACTION_TYPE_BUDGET) {
        const maxDailyBudget =
          providerId === CampaignsConsts.FACEBOOK_PROVIDER_ID
            ? Consts.FACEBOOK_DEFAULT_MAX_BUDGET
            : Consts.DEFAULT_MAX_BUDGET
        if (actionLimit > maxDailyBudget) {
          errors.set("actionLimit", "Action limit is too high")
        }
      }

      if (bidActions.includes(actionType) && actionLimit >= AutomationCreationConsts.ACTION_MAX_BID) {
        errors.set("actionLimit", "Action limit is too high")
      }
    }

    // Conditions
    if (isNullOrUndefined(conditions) || conditions.size === 0) {
      errors.set("conditions", "Filters are required")
    }

    // Schedule Type - Specific hours
    // add here the validation for the schedule type include the frequency validation

    return errors
  }

  prepareCreationData = (automationStatus = AutomationsConsts.AUTOMATION_STATUS_PAUSED) => {
    const isStatusOrSubSourceActionType = [Consts.ACTION_TYPE_STATUS, Consts.ACTION_TYPE_SUB_SOURCE_BLOCK].includes(
      this.state.selectedActionType.actionType
    )
    let isSpecificHours = this.state.selectedScheduleType.scheduleType === AutomationsConsts.SPECIFIC_HOURS
    // If the schedule type is specific hours, the frequency should be hourly only should add a filter for specific hour
    let selectedFrequency = isSpecificHours ? { frequency: "HOURLY" } : this.state.selectedFrequency

    let creationData = {
      status: automationStatus,
      name: this.state.automationName.replace(/\s+/g, " ").trim(),
      provider_id: this.state.selectedSource.providerId,
      action: {
        action_type: this.state.selectedActionType.actionType,
        action_value: this.state.actionValue,
        value_type: !isStatusOrSubSourceActionType ? this.state.selectedValueType.valueType : null,
        action_limit: !isStatusOrSubSourceActionType ? parseFloat(this.state.actionLimit) : null,
      },
      conditions: serializeConditions(this.state.conditions),
      time_frame: this.state.selectedTimeFrame.timeFrame,
      frequency: selectedFrequency.frequency,
    }
    if (isSpecificHours) {
      creationData.conditions.push({
        field: "specific_hours",
        value: this.state.selectedSpecificHours,
        operator: AutomationsConditionsTypes.filterOperators.IN,
      })
    }
    return creationData
  }

  createAutomation = () => {
    this.setState(
      {
        validationErrorsVisible: true,
      },
      () => {
        let validationErrors = this.validateAutomationCreation(this.state)

        if (validationErrors.size === 0) {
          let creationData = this.prepareCreationData()
          this.setState(
            {
              creationError: null,
              creationIsLoading: true,
            },
            () => {
              if (this.props.automationCreation.isEdit) {
                creationData.network_code = this.state.selectedNetwork.code
                creationData.status = this.state.automationStatus
                return this.editAutomationAsync(creationData)
              } else {
                return this.createAutomationAsync(creationData)
              }
            }
          )
        }
      }
    )
  }

  handleRequestError = (error) => {
    let errorMessage = "Something went wrong, please try again."

    if (!isNullOrUndefined(error?.response?.data?.message)) {
      errorMessage = error.response.data.message
    }

    this.setState({
      creationError: errorMessage,
      creationIsLoading: false,
    })
  }

  createAutomationAsync = async (creationData) => {
    try {
      const networkCode = this.state.selectedNetwork.code
      const response = await AutomationService.createAutomation(networkCode, creationData)
      if (response.data.rule_id) {
        const creationDataToAdd = Object.assign({}, creationData, {
          id: response.data.rule_id,
          network_code: networkCode,
        })
        this.props.dispatch(createAutomationResponse(creationDataToAdd))
      }
      this.closePopup()
    } catch (error) {
      this.handleRequestError(error)
    }
  }

  editAutomationAsync = async (editData) => {
    try {
      const response = await AutomationService.editAutomation(this.state.ruleId, editData)
      if (response && response.data) {
        this.props.dispatch(editAutomationResponse(this.state.ruleId, editData))
        this.closePopup()
      }
    } catch (error) {
      this.handleRequestError(error)
    }
  }

  render() {
    const creationError = !isNullOrUndefined(this.state.creationError) ? (
      <div className="automation-creation-errors">
        <span className="automation-error-text">{this.state.creationError}</span>
      </div>
    ) : null

    const isCreateButtonDisabled =
      this.state.validationErrorsVisible && this.validateAutomationCreation(this.state).size > 0

    const validationError = isCreateButtonDisabled ? (
      <div className="automation-creation-errors">
        <span className="automation-error-text">Please correct the validation errors in the creation fields</span>
      </div>
    ) : null

    let title = "Create New Rule"
    if (this.props.automationCreation.isEdit) {
      title = "Edit Rule"
    }

    return (
      <Dialog
        fullScreen
        open={this.props.automationCreation.isOpen}
        disableEnforceFocus={true}
        className="wizard-container"
        onClose={() => this.closePopup()}
        BackdropProps={{
          classes: { root: "campaign-creation-wizard-backdrop" },
        }}
        classes={{ paper: "campaign-creation-wizard-popup", root: "campaign-creation-wizard-container" }}
      >
        <Xicon
          className="campaign-creation-wizard-exit-button clickable"
          width="16"
          height="16"
          onClick={() => this.closePopup()}
        />
        <div className="central-wrapper">
          <div className="step-header">
            <div className="title">{title}</div>
          </div>
          <div className="step-content">
            <AutomationCreationDetails
              handleChange={this.handleChange}
              handleSelect={this.handleSelect}
              automationDetailsState={this.state}
              handleActionValueChange={this.handleActionValueChange}
              handleFilters={this.handleFilters}
              validateAutomationCreation={this.validateAutomationCreation}
              onSelectionChange={this.handleOperationTypeChange}
              handleSelectMultiple={this.handleSelectMultiple}
            />
            {validationError}
            {creationError}
            <div className="creation-wizard-navigation settings-navigation column-buttons">
              <Button
                color="primary"
                variant="contained"
                className="round-button green next-button save-automation-button"
                onClick={() => this.createAutomation()}
                disabled={isCreateButtonDisabled}
              >
                {this.state.creationIsLoading ? <CircularProgress size={20} /> : "Save"}
              </Button>
              <div onClick={() => this.closePopup()} className="clickable cancel-wizard">
                Back
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    automationCreation: state.automationCreation,
    currentNetwork: state.navigationBar.currentNetwork,
  }
}

export default connect(mapStateToProps)(AutomationCreationPopup)
