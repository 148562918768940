import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../common/components/genericDropdown"
import AutomationCreationConsts from "../../automationCreationConsts"
import AutomationsConsts from "../../../automations/automationsConsts"

const SourceDropdown = (props) => {
  const isSpecificHours = () => {
    return props.automationDetailsState.selectedScheduleType.scheduleType === AutomationsConsts.SPECIFIC_HOURS
  }
  // Generate menu items based on the schedule type
  const getMenuItems = () => {
    return isSpecificHours()
      ? Array.from({ length: 24 }, (_, i) => ({
          value: i,
          frequency: i,
          name: `${String(i).padStart(2, "0")}:00`,
        }))
      : AutomationCreationConsts.FREQUENCIES
  }
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [menuItems, setMenuItems] = useState(getMenuItems())
  const [selectedIndexesArr, setSelectedIndexesArr] = useState([0])

  useEffect(() => {
    const selectedValue = isSpecificHours()
      ? props.automationDetailsState.selectedSpecificHours
      : [props.automationDetailsState.selectedFrequency?.frequency]

    const selectedIndex = selectedValue
      ? menuItems
          .map((item, index) => (selectedValue.includes(item.frequency) ? index : -1))
          .filter((index) => index !== -1)
      : [0]

    setSelectedIndexesArr(selectedIndex)
  }, [])

  useEffect(() => {
    const updatedMenuItems = getMenuItems()
    if (updatedMenuItems.length !== menuItems.length) {
      setMenuItems(updatedMenuItems)
      setSelectedIndexesArr([0])
      props.handleSelect("selectedFrequency", AutomationCreationConsts.FREQUENCIES[0])
      props.handleSelect("selectedSpecificHours", isSpecificHours() ? [0] : null)
    }
  }, [props.automationDetailsState.selectedScheduleType])

  const handleMenuOpen = (event) => {
    setMenuOpen(true)
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  const handleMenuClick = (index) => {
    setSelectedIndexesArr(index)
    isSpecificHours()
      ? props.handleSelect("selectedSpecificHours", index)
      : props.handleSelect("selectedFrequency", menuItems[index])
    handleMenuClose()
  }

  return (
    <div className="text-field">
      <div className="creation-generic-dropdown-label">{props.automationDetailsState.selectedScheduleType.label}</div>
      <GenericDropdown
        header={
          selectedIndexesArr.length > 0
            ? menuItems
                .filter((_, index) => selectedIndexesArr.includes(index))
                .map((item) => item.name)
                .join(", ")
            : "No Selection"
        }
        selectOptionCallback={handleMenuClick}
        options={menuItems}
        open={menuOpen}
        anchorEl={menuAnchorEl}
        selectedIndexes={selectedIndexesArr}
        openDropdownCallback={handleMenuOpen}
        closeDropdownCallback={handleMenuClose}
        allowAutoSuggest={false}
        allowNoSelection={false}
        buttonClassName="creation-generic-dropdown-button darker-text"
        menuClassName="creation-generic-dropdown-menu"
        allowMultiple={isSpecificHours()}
      />
    </div>
  )
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(SourceDropdown)
