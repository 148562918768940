import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import GenericDropdown from "../../../common/components/genericDropdown"
import AutomationCreationConsts from "../../automationCreationConsts"
import AutomationsConsts from "../../../automations/automationsConsts"

const ScheduleTypeDropdown = (props) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [selectedIndexesArr, setSelectedIndexesArr] = useState([0])
  const menuItems = Object.values(
    props.isAdmin
      ? AutomationCreationConsts.SCHEDULE_TYPES
      : { [AutomationsConsts.REPEATING]: AutomationCreationConsts.SCHEDULE_TYPES[AutomationsConsts.REPEATING] }
  )

  useEffect(() => {
    if (props.automationDetailsState.selectedScheduleType?.scheduleType) {
      setSelectedIndexesArr([
        menuItems.findIndex(
          (item) => item.scheduleType === props.automationDetailsState.selectedScheduleType.scheduleType
        ),
      ])
    }
  }, [])

  const handleMenuOpen = (event) => {
    setMenuOpen(true)
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  const handleMenuClick = (index) => {
    setSelectedIndexesArr(index)
    const selectedScheduleType = menuItems[index]
    props.handleSelect("selectedScheduleType", selectedScheduleType)
    handleMenuClose()
  }

  const buttonClassName = "creation-generic-dropdown-button darker-text "

  return (
    <div className={"text-field"}>
      <div className="creation-generic-dropdown-label">Schedule Type</div>
      <GenericDropdown
        header={menuItems[selectedIndexesArr[0]]?.name}
        selectOptionCallback={(index) => {
          handleMenuClick(index)
        }}
        options={menuItems}
        open={menuOpen}
        anchorEl={menuAnchorEl}
        selectedIndexes={selectedIndexesArr}
        openDropdownCallback={(event) => {
          handleMenuOpen(event)
        }}
        allowAutoSuggest={false}
        closeDropdownCallback={handleMenuClose}
        allowNoSelection={false}
        buttonClassName={buttonClassName}
        menuClassName={"creation-generic-dropdown-menu"}
      />
    </div>
  )
}

function mapStateToProps(state, ownProps) {
  return { isAdmin: state.app.currentUser.is_admin }
}

export default connect(mapStateToProps)(ScheduleTypeDropdown)
