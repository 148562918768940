import React from "react"
import { connect } from "react-redux"
import CampaignsTableConsts from "../../../campaignsV2/campaignsTableConsts"
import { campaignPopupSortPerformanceList, updateCampaignPopupTablePage } from "../../campaignPopupActions"
import Pagination from "../../../common/components/pagination"
import Table from "../../../common/components/table/table"
import { isNullOrUndefined } from "../../../utils/funcUtils"
import SearchIcon from "../../../resources/svgs/SearchIcon.svg"
import { TextField } from "@material-ui/core"
import { getColumnsForTab } from "../../CampaignPopupColumns"

class CampaignPopupPerformanceBySubSourceList extends React.Component {
  constructor(props) {
    super(props)

    this.tableName = "performanceBySubSource"
    this.state = {
      searchSubSource: null,
    }

    this.columns = getColumnsForTab(this.props.campaign.provider_id, this.props.campaign.post_style, "sub_source")
  }

  sortSubSourcesBy = (column) => {
    this.props.dispatch(campaignPopupSortPerformanceList(column, "performanceBySubSource"))
  }

  setSearchSubSourceValue = (event) => {
    this.setState({ searchSubSource: event.target.value })
  }

  getHeader = () => {
    const cells = Object.values(this.columns).map((column) => ({
      fieldName: column.fieldName,
      value: column.title,
      clickable: true,
      cellSize: column.cellSize,
    }))

    return {
      cells,
    }
  }

  getRow = (subSource) => {
    const cells = Object.values(this.columns).map((column) => {
      const isCustomFormat = [
        CampaignsTableConsts.subSourceStatus.fieldName,
        CampaignsTableConsts.subSourceBid.fieldName,
      ].includes(column.fieldName)

      return {
        fieldName: column.fieldName,
        cssClasses: column.cssClasses || [],
        value: isCustomFormat
          ? column.defaultFormat(this.props.campaign, subSource)
          : column.defaultFormat(subSource[column.fieldName]),
        cellSize: column.cellSize,
      }
    })

    return { cells }
  }

  getSummaryRow = (summaryData) => {
    const cells = Object.values(this.columns).map((column) => {
      const isExcluded = [
        CampaignsTableConsts.subSourceStatus.fieldName,
        CampaignsTableConsts.subSource.fieldName,
        CampaignsTableConsts.subSourceBid.fieldName,
      ].includes(column.fieldName)

      return {
        fieldName: column.fieldName,
        value: isExcluded ? "--" : column.defaultFormat(summaryData[column.fieldName]),
      }
    })

    return { cssClasses: ["summary-row"], allowRowSelection: false, cells }
  }

  render() {
    const header = this.getHeader()
    let rows = []
    const { performanceBySubSource, summaryRow, currentPage, itemsPerPage } = this.props

    // Add summary row if data is available
    if (performanceBySubSource.length > 0) {
      rows.push(this.getSummaryRow(summaryRow))
    }

    // Apply search filter
    const filteredPerformanceBySubSource = isNullOrUndefined(this.state.searchSubSource)
      ? performanceBySubSource
      : performanceBySubSource.filter((performance) =>
          performance.sub_source.toLowerCase().includes(this.state.searchSubSource.toLowerCase())
        )

    // Add data rows with pagination
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = Math.min(currentPage * itemsPerPage, filteredPerformanceBySubSource.length)

    for (let i = startIndex; i < endIndex; i++) {
      rows.push(this.getRow(filteredPerformanceBySubSource[i]))
    }

    // Add pagination if necessary
    const pagination =
      filteredPerformanceBySubSource.length > itemsPerPage ? (
        <Pagination
          itemsArr={filteredPerformanceBySubSource.length}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          updatePage={(updatedPage) => {
            this.props.dispatch(updateCampaignPopupTablePage(updatedPage, this.tableName))
          }}
        />
      ) : null

    return (
      <div>
        <div className="align-items-end justify-content-end sub-source-search">
          <TextField
            id="sub-source-search-text-field"
            label="Search by Sub-Source"
            classes={{ root: "text-field" }}
            onChange={this.setSearchSubSourceValue}
            value={this.state.searchSubSource}
          />
          <span>
            <SearchIcon className="search-icon" style={{ marginLeft: "-10px", marginBottom: "4px" }} />
          </span>
        </div>
        <Table
          header={header}
          rows={rows}
          sort={{
            sortBy: this.props.sortBy,
            sortDirection: this.props.sortDirection,
            sortHandler: this.sortSubSourcesBy,
          }}
          scrolling={{
            containerClass: ".campaign-popup-container",
            includeNavigationBar: false,
          }}
          cssClasses={["campaign-popup-list"]}
          isLoadingData={performanceBySubSource.length === 0}
        />
        {pagination}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    sortBy: state.campaignPopupV2.performanceBySubSource.sortBy,
    sortDirection: state.campaignPopupV2.performanceBySubSource.sortDirection,
    performanceBySubSource: state.campaignPopupV2.performanceBySubSource.dataForTable,
    summaryRow: state.campaignPopupV2.performanceBySubSource.summaryRow,
    itemsPerPage: state.campaignPopupV2.performanceBySubSource.itemsPerPage,
    currentPage: state.campaignPopupV2.performanceBySubSource.currentPage,
    campaign: state.campaignPopupV2.campaign,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceBySubSourceList)
