import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { updateTargetingGroup } from "../../../campaignCreationActions"
import NumberFormat from "../../../../common/components/numberFormat"
import BidTypes from "../../../../common/consts/bidTypes"
import PlatformTypes from "../../../../common/consts/platformTypes"
import { deepEqual, isNullOrUndefined } from "../../../../utils/funcUtils"

const InitialBidAndBudget = (props) => {
  const { campaignTargetingGroup, conversionEvents, bidType, dispatch, groupIndex, onChange, isPreset } = props

  const [initialBid, setInitialBid] = useState(campaignTargetingGroup.initialBid)
  const [initialBudget, setInitialBudget] = useState(campaignTargetingGroup.initialBudget)
  const [initialBidInputIsDisabled, setInitialBidInputIsDisabled] = useState(false)
  const [lastKnownState, setLastKnownState] = useState({
    selectedConversionEvents: campaignTargetingGroup.selectedConversionEvents,
    platforms: campaignTargetingGroup.platforms,
  })
  const [lastBidType, setLastBidType] = useState(null)

  // Sync bid and budget from props
  useEffect(() => {
    setInitialBid(campaignTargetingGroup.initialBid)
    setInitialBudget(campaignTargetingGroup.initialBudget)
  }, [campaignTargetingGroup.initialBid, campaignTargetingGroup.initialBudget])

  // Handle bidType changes
  useEffect(() => {
    // When loading the preset, the initialBid and initialBudget are set to the preset values. We do not want to update these values when the bidType changes.
    // The check for bidType === lastBidType ensures that we do not update the initialBid/initialBudget multiple times if bidType has not actually changed.
    if (bidType !== lastBidType) {
      if (!(campaignTargetingGroup.isTargetingGroupPreset && isNullOrUndefined(lastBidType))) {
        if ([BidTypes.roas.value, BidTypes.highest_value.value, BidTypes.lowest_cost.value].includes(bidType)) {
          isPreset
            ? onChange({
                initialBid: bidType === BidTypes.roas.value ? BidTypes.roas.defaultBid : 0,
                initialBudget: bidType === BidTypes.lowest_cost.value ? initialBudget : BidTypes.roas.defaultBudget,
              })
            : props.dispatch(
                updateTargetingGroup(props.groupIndex, {
                  initialBid: bidType === BidTypes.roas.value ? BidTypes.roas.defaultBid : 0,
                  initialBudget: bidType === BidTypes.lowest_cost.value ? initialBudget : BidTypes.roas.defaultBudget,
                })
              )
        }
      }
      setLastBidType(bidType)
    }
    setInitialBidInputIsDisabled(
      [BidTypes.roas.value, BidTypes.highest_value.value, BidTypes.lowest_cost.value].includes(bidType)
    )
  }, [bidType])

  // Handle selectedConversionEvents and apply default values
  useEffect(() => {
    const currentState = {
      selectedConversionEvents: campaignTargetingGroup.selectedConversionEvents,
      platforms: campaignTargetingGroup.platforms,
    }
    // Added deepEqual to stop useEffect from running when nested objects haven't actually changed
    if (
      !deepEqual(lastKnownState, currentState) ||
      (campaignTargetingGroup.selectedConversionEvents.length > 0 && isNullOrUndefined(initialBudget))
    ) {
      if (campaignTargetingGroup.selectedConversionEvents.length > 0 && conversionEvents.length > 0) {
        const selectedEventValue = parseInt(campaignTargetingGroup.selectedConversionEvents[0].value)
        const conversionEvent = conversionEvents.find((item) => item.conversion_value === selectedEventValue)

        if (conversionEvent) {
          const selectedPlatform = Object.values(campaignTargetingGroup.platforms).find((platform) => platform.selected)

          let defaultBid = conversionEvent.default_multi_device_bid
          if (selectedPlatform) {
            switch (selectedPlatform.displayedName) {
              case PlatformTypes.DESKTOP.name:
                defaultBid = conversionEvent.default_desktop_bid
                break
              case PlatformTypes.MOBILE.name:
                defaultBid = conversionEvent.default_mobile_bid
                break
              default:
                break
            }
          }
          if ([BidTypes.roas.value, BidTypes.highest_value.value, BidTypes.lowest_cost.value].includes(bidType)) {
            defaultBid = bidType === BidTypes.roas.value ? BidTypes.roas.defaultBid : 0
          }
          if (
            campaignTargetingGroup.initialBid !== defaultBid ||
            campaignTargetingGroup.initialBudget !== conversionEvent.default_budget
          ) {
            isPreset
              ? onChange({
                  initialBid: defaultBid,
                  initialBudget: conversionEvent.default_budget,
                })
              : dispatch(
                  updateTargetingGroup(groupIndex, {
                    initialBid: defaultBid,
                    initialBudget: conversionEvent.default_budget,
                  })
                )
          }
        }
      }
      if (!deepEqual(lastKnownState, currentState)) {
        setLastKnownState(currentState)
      }
    }
  }, [campaignTargetingGroup.selectedConversionEvents, campaignTargetingGroup.platforms, lastKnownState])

  // Handle changes to bid or budget
  const onChangeEvent = (value, type) => {
    const updatedValues = type === "bid" ? { initialBid: value } : { initialBudget: value }

    if (isPreset) {
      onChange(updatedValues)
    } else {
      dispatch(updateTargetingGroup(groupIndex, updatedValues))
    }

    type === "bid" ? setInitialBid(value) : setInitialBudget(value)
  }

  return (
    <div>
      <div className="d-flex bid-budget-row">
        <div
          className={
            "text-field bid-budget-field" +
            (initialBidInputIsDisabled ? " disabled " : "") +
            (props.initialBidValidationErrors ? " error " : "")
          }
        >
          <div className="creation-generic-dropdown-label">Initial Bid</div>
          <NumberFormat
            value={initialBid}
            displayType={"input"}
            onValueChange={(values) => onChangeEvent(values.value, "bid")}
            className="number-text-field limit"
            decimalScale={2}
            thousandSeparator
            prefix={bidType === BidTypes.roas.value ? "%" : "$"}
            disabled={initialBidInputIsDisabled}
          />
          {props.initialBidValidationErrors}
        </div>
        <div className={"text-field bid-budget-field" + (props.initialBudgetValidationErrors ? " error " : "")}>
          <div className="creation-generic-dropdown-label">Initial Budget</div>
          <NumberFormat
            value={initialBudget}
            displayType={"input"}
            onValueChange={(values) => onChangeEvent(values.value, "budget")}
            className="number-text-field limit"
            decimalScale={0}
            thousandSeparator
            prefix={"$"}
          />
          {props.initialBudgetValidationErrors}
        </div>
      </div>
    </div>
  )
}

export default connect()(InitialBidAndBudget)
