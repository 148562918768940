import React from "react"
import { connect } from "react-redux"
import { campaignPopupSortPerformanceList, updateCampaignPopupTablePage } from "../../campaignPopupActions"
import Table from "../../../common/components/table/table"
import CampaignsTableConsts from "../../../campaignsV2/campaignsTableConsts"
import Pagination from "../../../common/components/pagination"
import { getColumnsForTab } from "../../CampaignPopupColumns"

class CampaignPopupPerformanceByCountryList extends React.Component {
  constructor(props) {
    super(props)

    this.tableName = "performanceByCountry"
    this.columns = getColumnsForTab(this.props.campaign.provider_id, this.props.campaign.post_style, "country")
  }

  sortCountriesBy = (column) => {
    this.props.dispatch(campaignPopupSortPerformanceList(column, this.tableName))
  }

  getHeader = () => {
    const cells = Object.values(this.columns).map((column) => ({
      fieldName: column.fieldName,
      value: column.title,
      clickable: true,
      cellSize: column.cellSize,
    }))

    return {
      cells,
    }
  }

  getRow = (country) => {
    const cells = Object.values(this.columns).map((column) => ({
      fieldName: column.fieldName,
      cssClasses: column.cssClasses || [],
      value: column.defaultFormat(country[column.fieldName]),
      cellSize: column.cellSize,
    }))

    return { cells }
  }

  getSummaryRow = (summaryData) => {
    const cells = Object.values(this.columns).map((column) => ({
      fieldName: column.fieldName,
      value:
        column.fieldName === CampaignsTableConsts.country.fieldName
          ? "Total"
          : column.defaultFormat(summaryData[column.fieldName]),
    }))

    return { cssClasses: ["summary-row"], allowRowSelection: false, cells }
  }

  render() {
    const header = this.getHeader()
    let rows = []
    const { performanceByCountry, summaryRow, currentPage, itemsPerPage } = this.props

    // Add summary row if data is available
    if (performanceByCountry.length > 0) {
      rows.push(this.getSummaryRow(summaryRow))
    }

    // Add data rows with pagination
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = Math.min(currentPage * itemsPerPage, performanceByCountry.length)

    for (let i = startIndex; i < endIndex; i++) {
      rows.push(this.getRow(performanceByCountry[i]))
    }

    // Add pagination if necessary
    const pagination =
      performanceByCountry.length > itemsPerPage ? (
        <Pagination
          itemsArr={performanceByCountry.length}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          updatePage={(updatedPage) => {
            this.props.dispatch(updateCampaignPopupTablePage(updatedPage, this.tableName))
          }}
        />
      ) : null

    return (
      <div>
        <Table
          header={header}
          rows={rows}
          sort={{
            sortBy: this.props.sortBy,
            sortDirection: this.props.sortDirection,
            sortHandler: this.sortCountriesBy,
          }}
          scrolling={{
            containerClass: ".campaign-popup-container",
            includeNavigationBar: false,
          }}
          cssClasses={["campaign-popup-list"]}
          isLoadingData={performanceByCountry.length === 0}
        />
        {pagination}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    sortBy: state.campaignPopupV2.performanceByCountry.sortBy,
    sortDirection: state.campaignPopupV2.performanceByCountry.sortDirection,
    performanceByCountry: state.campaignPopupV2.performanceByCountry.dataForTable,
    summaryRow: state.campaignPopupV2.performanceByCountry.summaryRow,
    itemsPerPage: state.campaignPopupV2.performanceByCountry.itemsPerPage,
    currentPage: state.campaignPopupV2.performanceByCountry.currentPage,
    campaign: state.campaignPopupV2.campaign,
  }
}

export default connect(mapStateToProps)(CampaignPopupPerformanceByCountryList)
